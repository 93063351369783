<script setup lang="ts">
const props = defineProps<{
  logo?: string
}>()

const { locale } = useI18n()
</script>

<template>
  <NuxtLinkLocale v-if="props?.logo && checkImage(props?.logo)" to="/" aria-label="logo" :hreflang="locale">
    <span class="sr-only">Logo</span>
    <img :src="props?.logo" alt="" class="max-w-40">
  </NuxtLinkLocale>
</template>
