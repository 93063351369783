<script setup lang="ts">
const { t, locale, defaultLocale }: any = useI18n()

const selectedIcon = computed(() => `/${locale.value}.svg`)

const currency = useCookie('currency')

async function changeCurrency(value: any) {
  await (currency.value = value)
  reloadNuxtApp({ force: true })
}

const localeItems = ref([
  [{
    label: t('language'),
    disabled: true,
  }, {
    label: 'English',
    avatar: {
      src: `/en.svg`,
    },
    click: async () => {
      await navigateTo(defaultLocale === 'en' ? '/' : '/en', { external: true })
    },
  }, {
    label: 'Tiếng Việt',
    avatar: {
      src: '/vi.svg',
    },
    click: async () => {
      await navigateTo(defaultLocale === 'vi' ? '/' : '/vi', { external: true })
    },
  }],
])

const sitedata: any = useSiteData()

// currencies
if (sitedata.value?.currencies?.length) {
  const currencyItem: any = [{
    label: t('currency'),
    slot: 'currency',
    disabled: true,
  }]
  sitedata.value?.currencies.forEach((x: any) => {
    currencyItem.push(
      {
        label: x,
        click: async () => {
          await changeCurrency(x)
        },
      },
    )
  })
  localeItems.value = [...localeItems.value, ...[currencyItem]]
}
</script>

<template>
  <UDropdown
    :items="localeItems"
    :ui="{ item: { disabled: 'cursor-text select-text', padding: 'px-1.5 py-2', size: 'text-base' } }"
    :popper="{ placement: 'bottom-start' }"
    class="py-3 sm:my-5 sm:my-0 print:hidden"
  >
    <UAvatar :src="selectedIcon" alt="" size="sm" />

    <span aria-label="Lang" class="sm:hidden truncate text-base px-3 text-gray-600">{{ t('language') }}</span>

    <template #currency="{ item }">
      <div class="text-left">
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ item.label }}
          <span v-if="currency">
            ({{ currency }})
          </span>
        </p>
      </div>
    </template>
  </UDropdown>
</template>
